/* +++++++++++++++++++++++++++ STYLE FOR LARGE SCREENS +++++++++++++++++++++++++++ */

/* Global */
a{text-decoration: none; color: inherit;}
.cc-primary-bg{ background-color: #003060 !important;}
.cc-primary-color{ color: #003060 !important; }
.cc-small-text{ letter-spacing: 1px; font-size: 15px; }
.h-80{ height: 80px;}
.h-120{ height: 120px;}
.cc-heading-a{ font-size: 32px; text-transform: uppercase; font-weight: 500; letter-spacing: 1px; color: #003060; margin: 40px 0;}
.cc-section-mt{  margin-top: 80px;}

/* Header */
.header-bar { position: fixed; top: 0; z-index: 100; width: 100%; transition: background-color 0.5s ease; box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1); }
.header-bar img.logo { width: 40px;}
.header-bar .title { font-size: 22px; margin-left: 14px;}
.header-bar .contact-button { border-color: white; color: white; border-radius: 10px;}
.header-bar .contact-button:hover { background-color: #a9a9a929;}
.header-bar .contact-button a { height: 100%; width: 100%; padding: 0 20px;}

/* Section 01 */
.section01 { position: relative; background-image: url(./media/bgImage01.webp); background-size: cover; 
    background-position: center top; height: 580px; width: 100%; overflow: hidden;}
.section01::before { content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);  z-index: 1;}
.section01 .section-content { max-width: 50%; position: relative;  z-index: 2; }
.section01 .section-content .section-heading { font-size: 44px; text-transform: uppercase; font-weight: 500; line-height: normal;}
.section01 .section-content .heading-ender { margin-top: 10px; bottom: -10px; height: 10px; opacity: 1;
    background: radial-gradient(ellipse at 50% 0, rgba(255, 255, 255, 0.6) 0, transparent 70%);}

/* Section AboutUs && Section AboutWork*/
.about-section { display: flex; align-items: center; margin-top: 80px;}
.about-section img{ max-width: 500px; height: 450px;}
.about-section img{ border-radius: 10px;}
.about-section .section-content{ border-radius: 10px; padding: 3%; z-index: 0;
    box-shadow: 0 4px 10px 0 rgba(4, 24, 116, 0.2); background-color: #fbfbfb;}
.about-section .section-content .section-heading{ font-size: 32px; text-transform: uppercase; font-weight: 500; margin-bottom: 10px; letter-spacing: 1px;}
.section-aboutUs.about-section .section-content{margin-left: -1%;}
.section-aboutWork.about-section .section-content{margin-right: -1%;}


/* Section Our Partners */
.section-ourPartners .partners { display: flex; flex-wrap: wrap;}
.section-ourPartners .partner-box{ flex: 1 1 20%;  max-width: 20%; padding: 10px;}
.section-ourPartners .partner-box .partner-content{ text-align: center; border-radius: 10px; padding: 14px 0;
    box-shadow: 0 2px 5px 0 rgba(87, 116, 230, 0.2); background-color: #fbfbfb;}
.section-ourPartners .partners .partner-box .partner-title{ text-transform: uppercase; font-weight: 500; letter-spacing: 2px; margin-top: 12px;}

/* Section Testimonials */
.section-testimonials .testimonials .testimonial{padding: 0 12px;}
.section-testimonials .testimonials .testimonial .testimonial-content{ border-radius: 10px; margin-bottom: 10px;
    padding: 30px; box-shadow: 0 2px 5px 0 rgba(87, 116, 230, 0.2); background-color: #fbfbfb;}
.section-testimonials .testimonials .testimonial .testimonial-content .review{ letter-spacing: 1px; min-height: 144px; display: flex; align-items: center;}
.section-testimonials .testimonials .testimonial .testimonial-content .clientName{ letter-spacing: 4px; font-size: 19px; font-weight: 500;}
.section-testimonials .testimonials .testimonial .testimonial-content .clientDes{ font-size: 12px; font-style: italic; color: #adadad;}

/* Section ContactUs */
.section-contactUs {border-radius: 10px; background-color: #fbfbfb; margin: 80px 0; 
    padding: 1px 50px 50px 50px; box-shadow: 0 2px 5px 0 rgba(87, 116, 230, 0.2); text-align: center;}

/* Footer */
.footer {padding: 30px 0;}
.footer .company-name { margin-bottom: 12px;}
.footer .company-description { font-size: 14px; margin-left: 10px;}
/* .footer .footer-column{ display: flex; flex-direction: column; justify-content: center;} */
.footer .column-heading{ margin-top: 26px; margin-bottom: 12px;}
.footer .info{ margin-bottom: 8px;}

.copyright{color: #272727; font-size: 13px; font-weight: 500;}

/* Separator  */
.separator { bottom: -10px; height: 10px; opacity: .6; margin-top: 80px;
    background: radial-gradient(ellipse at 50% 0, rgba(0, 0, 0, .3) 0, transparent 70%);}

/* Card Carousel Component  */
.cardCarousel .card{ border: 0; box-shadow: 0 4px 8px 0 rgba(4, 24, 116, 0.2); border-radius: 10px; overflow-x: hidden;}
.cardCarousel .card .card-body .card-title { height: 40px; display: flex; align-items: center; justify-content: center; }
.cardCarousel .card .card-body .card-text {overflow: hidden; text-align: center;}
.cardCarousel .slick-list { padding-bottom: 10px !important;}

/* Slick Slider  */
.slick-prev, .slick-next{ width: 30px !important; height: 30px !important; z-index: 1;}
.slick-prev:before, .slick-next:before { color: #054074 !important; font-size: 28px !important;}



/* +++++++++++++++++++++++++++ STYLE FOR SMALL SCREENS +++++++++++++++++++++++++++ */
@media (max-width: 1200px) {
    .section-testimonials .testimonials .testimonial .testimonial-content{ padding: 20px 12px;}
}

@media (max-width: 992px) {
    /* Global  */
    .cc-heading-a{ text-align: center; font-size: 24px; margin: 25px 0;}

    /* Header */
    .header-bar { justify-content: center !important;}
    .header-bar img.logo { width: 34px;}
    .header-bar .title { font-size: 15px; margin-left: 8px;}
    .header-bar .contact-button { display: none;}
    
    /* Section 01 */
    .section01 .section-content { max-width: 90%;}
    .section01 .section-content .section-heading { font-size: 26px;}

    /* Section AboutUs && Section AboutWork*/
    .about-section { margin-top: 30px;}
    .about-section .section-content .section-heading{ text-align: center; font-size: 26px;}
    .about-section img{ display: none;}

    /* Section Our Partners */
    .section-ourPartners .partner-box { flex: 1 1 50%; max-width: 50%;}

    /* Section Testimonials */
    .section-contactUs {margin: 25px 0; 
        padding: 1px 10px 20px 10px;}

    /* Footer */
    /* .footer {padding: 16px 15px;} */
    .footer .company-name { margin-bottom: 10px;}
    .footer .company-description { font-size: 14px;}
    .footer .column-heading{ margin-top: 10px; margin-bottom: 4px;}
    .footer .info{ margin-bottom: 2px;}
    .footer .followUs{ display: flex; align-items: center; gap: 10px; }


    /* Separator  */
    .separator { margin-top: 50px;}

    /* Slick Slider  */
    .slick-slider { max-width: 98%; margin: 0 auto;}
    .slick-slider .slick-prev { left: -15px;}
    .slick-slider .slick-next { right: -15px;}
}

@media (max-width: 767px) {
    .footer .footer-column{ padding-left: 22px;}
}

